<template>
  <section class="einfacher-text-mit-titel constrain-4/5">
    <div v-if="!payload.titelausblenden.length" v-view class="animate in-left text-center mb-8">
      <h1 class="h2" v-text="payload.title" />
    </div>
    <div v-view class="animate in-left" v-html="payload.text" />
  </section>
</template>

<script>
export default {
  props: { payload: { type: Object, default: Object } },
};
</script>
